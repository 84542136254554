var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex items-center"
  }, [_c('button', {
    staticClass: "mr-3 w-8 h-8 flex justify-center items-center focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.toClassListPage();
      }
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Class Attribute")])]), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-6"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "border-b border-neutral-300 mb-4"
  }, [_c('h1', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Attributes ")]), _c('p', {
    staticClass: "text-xs text-neutral-400 pl-6 pt-1 pb-2"
  }, [_vm._v("Attributes are used when a class has multiple properties, like Location or Time")])]), _vm._l(_vm.classAttributes, function (attribute, attributeIndex) {
    return _c('div', {
      key: 'attributeIndex' + attributeIndex,
      staticClass: "p-6 bg-neutral-100 rounded-lg mb-5"
    }, [_c('div', {
      staticClass: "flex justify-between items-center mb-2"
    }, [_c('h1', {
      staticClass: "text-sm font-semibold"
    }, [_vm._v(_vm._s("Attribute ".concat(attributeIndex + 1, " - ").concat(attribute.attributeName)))]), _c('div', {
      staticClass: "flex gap-2 items-center"
    }, [attribute.isEdit ? _c('Button', {
      staticClass: "w-7 h-7",
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        },
        "forIcon": {
          height: '18',
          width: '18',
          color: 'yellow'
        }
      },
      on: {
        "mouse": _vm.handleMousEvent,
        "action": function action($event) {
          return _vm.showConfirmDeleteAttribute(_vm.classAttributes, attributeIndex);
        }
      }
    }) : _c('Button', {
      staticClass: "w-7 h-7",
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Pencil');
        },
        "forIcon": {
          height: '18',
          width: '18',
          color: 'yellow'
        }
      },
      on: {
        "action": function action($event) {
          return _vm.switchMode(attribute);
        }
      }
    })], 1)]), attribute.isEdit ? _c('div', {}, [_c('TextField', {
      staticClass: "w-full mb-3",
      attrs: {
        "type": "text",
        "notes": "E.g. Delivery Time, Location",
        "label": "Attribute Name",
        "borderEnabled": true,
        "placeholder": "Enter Attribute Name"
      },
      model: {
        value: attribute.attributeName,
        callback: function callback($$v) {
          _vm.$set(attribute, "attributeName", $$v);
        },
        expression: "attribute.attributeName"
      }
    }), _c('div', [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Attribute Value")]), _c('div', {
      staticClass: "space-y-1"
    }, _vm._l(attribute.attributeItems, function (item, itemIdx) {
      return _c('div', {
        key: 'classAttributeItem.values.edit' + itemIdx
      }, [_c('div', {
        staticClass: "flex gap-3 items-center"
      }, [_c('TextField', {
        staticClass: "w-11/12",
        attrs: {
          "type": "text",
          "borderEnabled": "",
          "placeholder": "Input variant value, e.g. Online",
          "indexData": attributeIndex
        },
        on: {
          "onBlur": function onBlur($event) {
            var _vm2;

            return (_vm2 = _vm).handleNoteVisibility.apply(_vm2, [attribute].concat(Array.prototype.slice.call(arguments)));
          },
          "onEnter": function onEnter($event) {
            var _vm3;

            return (_vm3 = _vm).handlKeyEnter.apply(_vm3, [attribute.attributeItems].concat(Array.prototype.slice.call(arguments)));
          }
        },
        model: {
          value: attribute.attributeItems[itemIdx].attributeValue,
          callback: function callback($$v) {
            _vm.$set(attribute.attributeItems[itemIdx], "attributeValue", $$v);
          },
          expression: "attribute.attributeItems[itemIdx].attributeValue"
        }
      }), _c('div', {
        staticClass: "flex items-center"
      }, [attribute.attributeItems.length > 1 ? _c('Button', {
        attrs: {
          "type": "secondary",
          "size": "icon",
          "icon": function icon() {
            return import(
            /* webpackChunkName: 'icon' */
            '@/components/Icons/Trash');
          }
        },
        on: {
          "mouse": _vm.handleMousEvent,
          "action": function action($event) {
            return _vm.showConfirmDeleteAttributeItem(attribute.attributeItems, attributeIndex, itemIdx);
          }
        }
      }) : _vm._e()], 1)], 1)]);
    }), 0), attribute.isShowNotes ? _c('div', {
      staticClass: "text-xs text-gray-500 mt-1 leading-tight inline-block"
    }, [_vm._v("Click enter to add new value")]) : _vm._e()]), _c('div', {
      staticClass: "flex gap-2 items-center pt-3"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Save",
        "disabled": attribute.attributeName.length === 0 || !_vm.isAttributeItemsValid(attribute)
      },
      on: {
        "mouse": _vm.handleMousEvent,
        "action": function action($event) {
          return _vm.saveClassAttributes(attribute);
        }
      }
    }), attribute.showCancelBtn ? _c('Button', {
      attrs: {
        "buttonText": "Cancel",
        "type": "secondary"
      },
      on: {
        "mouse": _vm.handleMousEvent,
        "action": function action($event) {
          return _vm.switchMode(attribute);
        }
      }
    }) : _vm._e()], 1)], 1) : _c('div', {
      staticClass: "flex gap-2 flex-wrap"
    }, _vm._l(attribute.attributeItems, function (item, itemIdx) {
      return _c('p', {
        key: 'classAttributeItem.values' + itemIdx,
        staticClass: "text-xs font-medium py-1 px-3 rounded-lg bg-neutral-50"
      }, [_vm._v(" " + _vm._s(item.attributeValue) + " ")]);
    }), 0)]);
  }), _c('div', {
    staticClass: "pt-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add Another Attribute",
      "type": "tertiary"
    },
    on: {
      "action": _vm.addClassAttribute
    }
  })], 1)], 2)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.confirmationModalVisible,
      "id": "delete-class-attribute"
    },
    on: {
      "close": _vm.toggleConfirmModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Are you sure to delete "), _c('br'), _vm._v(_vm._s(_vm.deleteItemSelected.type) + " - " + _vm._s(_vm.deleteItemSelected.name) + " ?")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Confirm",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.confirmDelete(_vm.deleteItemSelected.type);
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary"
    },
    on: {
      "action": _vm.toggleConfirmModal
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }