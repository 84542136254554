<template>
  <div class="min-h-screen">
    <div class="mb-6 flex items-center">
      <button @click="toClassListPage()" class="mr-3 w-8 h-8 flex justify-center items-center focus:outline-none">
        <ArrowForward direction="left" />
      </button>
      <h1 class="text-3xl font-bold">Class Attribute</h1>
    </div>
    <div class="shadow-small rounded-lg bg-white p-6">
      <div class="w-1/2">
        <div class="border-b border-neutral-300 mb-4">
          <h1 class="font-semibold flex gap-1 items-center">
            <span class="-ml-1"><Label color="yellow" /></span>Attributes
          </h1>
          <p class="text-xs text-neutral-400 pl-6 pt-1 pb-2">Attributes are used when a class has multiple properties, like Location or Time</p>
        </div>
        <div class="p-6 bg-neutral-100 rounded-lg mb-5" v-for="(attribute, attributeIndex) in classAttributes" :key="'attributeIndex' + attributeIndex">
          <div class="flex justify-between items-center mb-2">
            <h1 class="text-sm font-semibold">{{ `Attribute ${attributeIndex + 1} - ${attribute.attributeName}` }}</h1>
            <div class="flex gap-2 items-center">
              <Button
                v-if="attribute.isEdit"
                @mouse="handleMousEvent"
                type="secondary"
                size="icon"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                :forIcon="{ height: '18', width: '18', color: 'yellow' }"
                @action="showConfirmDeleteAttribute(classAttributes, attributeIndex)"
                class="w-7 h-7"
              />
              <Button
                v-else
                type="secondary"
                size="icon"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                :forIcon="{ height: '18', width: '18', color: 'yellow' }"
                @action="switchMode(attribute)"
                class="w-7 h-7"
              />
            </div>
          </div>
          <div v-if="attribute.isEdit" class="">
            <TextField
              type="text"
              notes="E.g. Delivery Time, Location"
              label="Attribute Name"
              :borderEnabled="true"
              placeholder="Enter Attribute Name"
              v-model="attribute.attributeName"
              class="w-full mb-3"
            />
            <div>
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Attribute Value</label>
              <div class="space-y-1">
                <div class="" v-for="(item, itemIdx) in attribute.attributeItems" :key="'classAttributeItem.values.edit' + itemIdx">
                  <div class="flex gap-3 items-center">
                    <TextField
                      type="text"
                      borderEnabled
                      placeholder="Input variant value, e.g. Online"
                      v-model="attribute.attributeItems[itemIdx].attributeValue"
                      :indexData="attributeIndex"
                      class="w-11/12"
                      @onBlur="handleNoteVisibility(attribute, ...arguments)"
                      @onEnter="handlKeyEnter(attribute.attributeItems, ...arguments)"
                    />
                    <div class="flex items-center">
                      <Button
                        @mouse="handleMousEvent"
                        v-if="attribute.attributeItems.length > 1"
                        type="secondary"
                        size="icon"
                        :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                        @action="showConfirmDeleteAttributeItem(attribute.attributeItems, attributeIndex, itemIdx)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="attribute.isShowNotes" class="text-xs text-gray-500 mt-1 leading-tight inline-block">Click enter to add new value</div>
            </div>

            <div class="flex gap-2 items-center pt-3">
              <Button buttonText="Save" @mouse="handleMousEvent" @action="saveClassAttributes(attribute)" :disabled="attribute.attributeName.length === 0 || !isAttributeItemsValid(attribute)" />
              <Button v-if="attribute.showCancelBtn" @mouse="handleMousEvent" buttonText="Cancel" type="secondary" @action="switchMode(attribute)" />
            </div>
          </div>
          <div v-else class="flex gap-2 flex-wrap">
            <p class="text-xs font-medium py-1 px-3 rounded-lg bg-neutral-50" v-for="(item, itemIdx) in attribute.attributeItems" :key="'classAttributeItem.values' + itemIdx">
              {{ item.attributeValue }}
            </p>
          </div>
        </div>
        <div class="pt-4">
          <Button buttonText="Add Another Attribute" type="tertiary" @action="addClassAttribute" />
        </div>
      </div>
    </div>
    <Modal :modalVisible="confirmationModalVisible" @close="toggleConfirmModal" id="delete-class-attribute">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-5">Are you sure to delete <br />{{ deleteItemSelected.type }} - {{ deleteItemSelected.name }} ?</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Confirm" type="secondary" @action="confirmDelete(deleteItemSelected.type)" />
            <Button buttonText="Cancel" type="primary" @action="toggleConfirmModal" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'ClassAttributes',
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  data: function () {
    return {
      classAttributes: [],
      isSaveButtonHovered: false,
      confirmationModalVisible: false,
      deleteItemSelected: {
        type: '',
        name: '',
        id: '',
        index: '',
        indexChild: ''
      },
      attributeItemsTemporary: []
    }
  },

  created() {
    this.getClassAttributes()
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    }
  },
  watch: {},
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'getTestimonyList']),
    ...mapActions('kelas', ['CREATE_CLASS_ATTRIBUTE', 'GET_CLASS_ATTRIBUTE_LIST', 'UPADATE_CLASS_ATTRIBUTE', 'DELETE_CLASS_ATTRIBUTE']),
    showConfirmDeleteAttribute(classAttributes, selectedIndex) {
      // check if is new attribute and no value indeed
      if (
        classAttributes[selectedIndex].attributeName.length === 0 ||
        (!this.isAttributeItemsNoEmptyValue(classAttributes[selectedIndex].attributeItems) && !classAttributes[selectedIndex]?.attributeId)
      ) {
        return this.deleteItem(classAttributes, selectedIndex)
      }
      this.deleteItemSelected.type = 'attribute'
      this.deleteItemSelected.name = classAttributes[selectedIndex].attributeName
      // Call API
      if (classAttributes[selectedIndex]?.attributeId) {
        this.deleteItemSelected.id = classAttributes[selectedIndex]?.attributeId
        this.deleteItemSelected.index = ''
      } else {
        // Delete Local
        this.deleteItemSelected.id = ''
        this.deleteItemSelected.index = selectedIndex
      }
      this.toggleConfirmModal()
    },
    showConfirmDeleteAttributeItem(classAttributeItem, parentIndex, childIndex) {
      this.deleteItemSelected.type = 'attribute item'
      this.deleteItemSelected.name = classAttributeItem[childIndex].attributeValue ? classAttributeItem[childIndex].attributeValue : 'empty value'
      this.deleteItemSelected.id = this.classAttributes[this.deleteItemSelected.index] ? classAttributeItem?.attributeValueId : ''
      this.deleteItemSelected.index = parentIndex
      this.deleteItemSelected.childIndex = childIndex
      this.toggleConfirmModal()
    },
    toggleConfirmModal() {
      this.confirmationModalVisible = !this.confirmationModalVisible
    },
    confirmDelete() {
      if (this.deleteItemSelected.type === 'attribute') return this.deleteAttribute()
      const attribute = this.classAttributes[this.deleteItemSelected.index]
      if (attribute.attributeItems[this.deleteItemSelected.childIndex]?.attributeValueId) {
        this.deleteItem(attribute.attributeItems, this.deleteItemSelected.childIndex)
        attribute.attributeItems = attribute.attributeItems.filter((item) => item.attributeValue)
        this.saveClassAttributes(attribute)
      } else {
        this.deleteItem(attribute.attributeItems, this.deleteItemSelected.childIndex)
      }
      this.toggleConfirmModal()
    },
    deleteAttribute() {
      // Call API
      if (this.deleteItemSelected.id !== '') {
        this.showLoading()
        this.DELETE_CLASS_ATTRIBUTE({ customerId: this.customerId, attributeId: this.deleteItemSelected.id })
          .then((res) => {
            if (res?.data?.code === 200) {
              this.toggleConfirmModal()
              this.getClassAttributes()
              showVueToast('Success', 'success', 2000)
            } else {
              this.hideLoading()
              showVueToast('Failed', 'error', 2000)
            }
          })
          .catch(() => {
            this.hideLoading()
            showVueToast('Failed', 'error', 2000)
          })
      } else {
        // delete local
        this.deleteItem(this.classAttributes, this.deleteItemSelected.index)
        this.toggleConfirmModal()
      }
    },
    isAttributeItemsValid(attribute) {
      return this.isAttributeItemsNoDuplicate(attribute.attributeItems, 'attributeValue') && this.isAttributeItemsNoEmptyValue(attribute.attributeItems)
    },
    isAttributeItemsNoEmptyValue(attributeItems) {
      return attributeItems.filter((item) => item.attributeValue.length === 0).length === 0
    },
    isAttributeItemsNoDuplicate(objectItem, property) {
      if (objectItem.length === 1) return true
      const uniqueValues = new Set()
      return objectItem.every((item) => {
        if (!uniqueValues.has(item[property])) {
          uniqueValues.add(item[property])
          return true
        }
        return false
      })
    },
    handleMousEvent(value) {
      this.isSaveButtonHovered = value
    },
    getClassAttributes() {
      this.showLoading()
      const params = {
        page: 0,
        size: 200,
        sortBy: 'createdDate',
        direction: 'ASC',
        attributeName: '',
        attributeValue: ''
      }
      this.GET_CLASS_ATTRIBUTE_LIST({ params, customerId: this.customerId })
        .then((res) => {
          if (res?.data?.code === 200) {
            this.classAttributes = res.data.data.map((attribute) => {
              attribute.isEdit = false
              attribute.isShowNotes = false
              attribute.showCancelBtn = true
              return {
                ...attribute
              }
            })
          }
          this.hideLoading()
        })
        .then(() => {
          this.hideLoading()
        })
    },
    saveClassAttributes(attribute) {
      this.showLoading()
      attribute.isShowNotes = false
      const payload = { ...attribute }
      delete payload.isEdit
      delete payload.isShowNotes
      delete payload.showCancelBtn
      // Update Class Attribute
      if (attribute?.attributeId) {
        this.UPADATE_CLASS_ATTRIBUTE({ customerId: this.customerId, payload, attributeId: attribute?.attributeId })
          .then((res) => {
            if (res?.data?.code === 200) {
              this.getClassAttributes()
              showVueToast('Success', 'success', 2000)
            } else {
              this.hideLoading()
              showVueToast('Failed', 'error', 2000)
            }
          })
          .catch(() => {
            this.hideLoading()
            showVueToast('Failed', 'error', 2000)
          })
        return
      }
      // Create New Class Attributes
      this.CREATE_CLASS_ATTRIBUTE({ customerId: this.customerId, payload })
        .then((res) => {
          if (res?.data?.code === 200) {
            this.getClassAttributes()
            showVueToast('Success', 'success', 2000)
          } else {
            this.hideLoading()
            showVueToast('Failed', 'error', 2000)
          }
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Failed', 'error', 2000)
        })
    },
    handleNoteVisibility(objectItem, status) {
      if (this.isSaveButtonHovered) return
      objectItem.isShowNotes = status
    },
    toClassListPage() {
      this.$router.push('/kelas')
    },
    addClassAttribute() {
      const item = {
        attributeName: '',
        attributeItems: [
          {
            attributeValue: ''
          }
        ],
        isEdit: true,
        isShowNotes: false,
        showCancelBtn: false
      }
      this.classAttributes.push(item)
    },
    handlKeyEnter(objectItem) {
      objectItem.push({
        attributeValue: ''
      })
    },
    deleteItem(objectItem, selectedIndex) {
      objectItem.splice(selectedIndex, 1)
    },
    switchMode(objectItem) {
      if (!objectItem.isEdit) {
        this.handlKeyEnter(objectItem.attributeItems)
        const filterArrayNoValue = objectItem.attributeItems.filter((item) => item.attributeValue)
        this.attributeItemsTemporary = JSON.parse(JSON.stringify(filterArrayNoValue))
        objectItem.isEdit = true
        return
      }
      objectItem.attributeItems = this.attributeItemsTemporary
      objectItem.isEdit = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>